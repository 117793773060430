










































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThingsMethodEntityModel, ThingsMethodQueryModel } from '@common-src/entity-model/things-method-entity';
import ThingsMethodService from '@common-src/service/things-method';
import ThingsSyncDialog from '../../things-template/things-sync-dialog.vue';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';

@Component({
    components: {
        'things-sync-dialog': ThingsSyncDialog
    }
})
export default class ThingsServiceListComponent extends TableDialogFormComponent<ThingsMethodEntityModel, ThingsMethodQueryModel> {
    ThingsMethodEntityModel = ThingsMethodEntityModel;
    created() {
        this.initTable({ service: ThingsMethodService, queryModel: new ThingsMethodQueryModel(), tableColumns: ThingsMethodEntityModel.getTableColumns() });
        this.getList();
    }

    upSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.UPLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.METHOD);
    }

    downSysncClick() {
        (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.METHOD);
    }
}

